// ==================== SETTINGS MENU ====================

div#root div.Settings {
    
    text-align: center;

    h1 {
        font-weight: bold;
    }

}
