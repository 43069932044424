@charset 'utf-8';
@import url('https://fonts.googleapis.com/css2?family=Goldman&display=swap');

// import color palette
@import "_variables";


html, body {
  font-family: 'Goldman', cursive;
  font-weight: normal;
  background-color: $rich-black-fogra-29;
  color: $shadow-blue;
}

button {
  outline: none;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 3px;
  font-weight: bold;
}

div#root {
  font-size: 18px;

  button.menu-button {
    background-color: $shadow-blue;
    color: $platinum;
    width: 220px;
    margin: 8px auto;

    &:hover {
      background-color: darken($shadow-blue, 5%);
    }
  }

}




